@import 't4b-core-frontend/src/css/colors';
@import '/src/styles/_colors.scss';

.productCard {
  width: 23rem;
  margin: 8px 8px;
  border: 1px solid rgba(212, 212, 212, 0.85);

}

.header {
  background-color: #f2f1f1 !important;
  padding-bottom: 0;
  padding-right: 8px;
}

.productStatus {
  display: flex;
  justify-content: space-between;
  height: 2rem;
}

.description,
.descriptionAppName {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 9px;
}

.description {
  margin-bottom: 15px;
}

.descriptionHovered,
.descriptionNotHovered {
  width: 65%;
  white-space: nowrap;
  padding-bottom: 6px;
}

.descriptionNotHovered {
  text-overflow: ellipsis;
  overflow: hidden;
}

.control {
  margin-top: 18px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
}

.active,
.idle,
.changing,
.inactive {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
}

.active {
  background-color: #6FD1B9;
}

.idle {
  background-color: #F0E478;
}

.changing {
  background-color: #CFD4D9;
}

.inactive {
  background-color: #F48A8A;
}

.showInfo {
  text-decoration: none;
  color: $app-color-reset;

  &:hover {
    color: $app-color-reset-dark;
  }
}
